import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createGoal } from '../_redux/goalActions';
import { fetchGoalSplitPieChartData } from '../../Portfolio/_redux/goalActions';
import { useDispatch, useSelector } from 'react-redux';

import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';

import AreaChart from '../components/AreaChart';
import RiskProfileInformation from '../../Common/RiskProfileInformation';
import ErrorCard from '../../Common/ErrorCard';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import MainWrapper from '../../Pocket/components/MainWrapper';

import {
  getTargetDate,
  investmentPeriod,
} from '../../../helpers/investmentHelpers';
import TextModal from '../components/LearnMore';
import Back from '../../Common/BackArrow';
import ReusableModal from '../../Common/ReusableModal';
import { PaymentMethodModalContent } from '../../Common/PaymentMethodModalContent';
import { InvestmentAction, paymentMethod } from '../../../helpers/constants';
import { investInGoalFromPocket } from '../../Pocket/_redux/axiosRequest';
import Alert from '../../Common/Alert';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { GoalSummaryInfo } from '../components/GoalSummaryInfo';
import { ConfirmButton } from '../../Common/ReusableButtons';
import useRiskLevels from '../../../helpers/CustomHook/useRiskLevels';
import { GoalSummaryItem } from '../components/GoalSummaryItem';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';

const GoalSummary = () => {
  let history = useHistory();
  const [activePaymentMethod, setActivePaymentMethod] = useState(false);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );
  const pocketDetails = useSelector(state => state.pocketDetails);
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const [buttonLoading, setButtonLoading] = useState(false);
  const alertInitialState = {
    alertMessage: null,
    alertMessageType: null,
  };
  const [alert, setAlert] = useState(alertInitialState);

  const [loading, setLoading] = useState(false);
  const [percentages, setPercentages] = useState([]);
  const [labels, setLabels] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);

  const { updateRiskLevels, error } = useRiskLevels();

  const dispatch = useDispatch();

  const handleErrorMessage = errorMessage => {
    setAlert({ alertMessage: errorMessage, alertMessageType: 'error' });

    setButtonLoading(false);
  };

  const createTheGoal = async () => {
    setButtonLoading(true);
    const existingGoalId = reduxGoalInvestmentDtls?.goalId;
    if (existingGoalId)
      return history.push(
        `/new-investment/investorInformation/${existingGoalId}`,
      );

    const nameRequired = reduxGoalInvestmentDtls?.nameRequired;
    const riskLevel = reduxGoalInvestmentDtls?.riskLevel;

    const payload = {
      goalAmount: convertAmountToNumber(reduxGoalInvestmentDtls?.targetAmount),
      currency: reduxGoalInvestmentDtls?.currency,
      targetDate: getTargetDate(
        reduxGoalInvestmentDtls?.years,
        reduxGoalInvestmentDtls?.months,
      ),
      recurringAmount: convertAmountToNumber(
        reduxGoalInvestmentDtls?.monthlyAmount,
      ),
      initialInvestment: convertAmountToNumber(
        reduxGoalInvestmentDtls?.initialAmount,
      ),
      investmentProfileId: Number(riskLevel),
      staticGoalId: Number(reduxGoalInvestmentDtls?.staticGoalId),
    };

    if (nameRequired) {
      payload.name = reduxGoalInvestmentDtls?.goalName;
    }

    try {
      const response = await createGoal(payload);
      const goalId = response?.data?.data?.id;

      if (activePaymentMethod === paymentMethod.POCKET) {
        try {
          await investInGoalFromPocket({
            amount: convertAmountToNumber(
              reduxGoalInvestmentDtls?.initialAmount,
            ),
            id: goalId,
            type: reduxGoalInvestmentDtls?.investmentType,
            pocketId: pocketDetails?.id,
          });
          setButtonLoading(false);
          setActivePaymentMethod(null);
          setOpenPaymentMethodModal(null);
          setOpenCongratulationsModal(true);
        } catch (error) {
          handleErrorMessage(error?.response?.data?.message);
          setButtonLoading(false);
        }
      } else {
        setButtonLoading(false);
        dispatch(
          goalReducerActions.updateGoalInvestmentDetails({
            ...reduxGoalInvestmentDtls,
            amount: convertAmountToNumber(
              reduxGoalInvestmentDtls?.initialAmount,
            ),
            goalId,
            currency: response?.data?.data?.currency,
          }),
        );

        dispatch(
          investmentReducerActions.updateInvestmentAction(
            InvestmentAction.GOAL_INVESTMENT,
          ),
        );
        history.push(`/new-investment/investorInformation/${goalId}`);
      }
    } catch (error) {
      handleErrorMessage(error?.response?.data?.message);
      setButtonLoading(false);
    }
  };

  // ⛔️ DO NOT REMOVE THIS UNUSED FUNCTION, IT MIGHT BE USED IN THE FUTURE ⛔️
  const fetchPlansDetails = async () => {
    const riskLevel = reduxGoalInvestmentDtls?.riskLevel;

    try {
      setLoading(true);
      let res = await fetchGoalSplitPieChartData(riskLevel);
      if (res.status === 200) {
        const dataObj = res.data.data;
        const keys = Object.keys(dataObj);
        setPercentages(Object.values(dataObj));
        setLabels(keys.map(key => key));
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(err?.response?.data?.message);
    }
  };

  // ⛔️ DO NOT REMOVE THIS CODE, IT MIGHT BE NEEDED IN FUTURE ⛔️

  // useEffect(() => {
  //   fetchPlansDetails();
  // }, []);

  useEffect(() => {
    updateRiskLevels();
  }, [updateRiskLevels]);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : error?.alertMessage ? (
        <ErrorCard errorMessage={error?.alertMessage} />
      ) : (
        <AnimatedPageContainer>
          <Back
            text={'Back'}
            onClick={() => history.push('/goal/calculator')}
            title={'Goal Summary'}
          />

          <MainWrapper>
            {/* <div className="goal-investment-summary donut-chart-container"> */}
            <GoalSummaryItem
              label={'Goal Name'}
              value={reduxGoalInvestmentDtls?.goalName}
              addHorizontalLine={true}
            />

            <GoalSummaryItem
              label={'Target Date'}
              value={getTargetDate(
                reduxGoalInvestmentDtls?.years,
                reduxGoalInvestmentDtls?.months,
              )}
              addHorizontalLine
            />

            <GoalSummaryItem
              label={'Investment Period'}
              value={
                investmentPeriod(
                  Number(
                    (
                      (reduxGoalInvestmentDtls?.years * 12 +
                        reduxGoalInvestmentDtls?.months) /
                      12
                    )
                      .toString()
                      .split('.')[0],
                  ),
                ).title
              }
              addHorizontalLine={false}
            />

            <GoalSummaryInfo
              label={'Goal Target'}
              amount={reduxGoalInvestmentDtls?.targetAmount}
              currency={reduxGoalInvestmentDtls?.currency}
            />

            <GoalSummaryInfo
              label={'Initial Investment'}
              amount={reduxGoalInvestmentDtls?.initialAmount}
              currency={reduxGoalInvestmentDtls?.currency}
            />

            <GoalSummaryInfo
              label={'Monthly Recurring Investment'}
              amount={reduxGoalInvestmentDtls?.monthlyAmount}
              currency={reduxGoalInvestmentDtls?.currency}
            />
            {/* </div> */}
          </MainWrapper>

          {/* REMOVING THE DONUT CHART FOR GOAL DISTRIBUTION
            ⛔️ DO NOT REMOVE THIS CODE, IT MIGHT BE NEEDED IN FUTURE ⛔️ */}

          {/* <div className="card internal-container card-gutter-b goal-investment-summary donut-chart-container p-5 ">
              <div
                onClick={handleShow}
                style={{ cursor: 'pointer', width: 'fit-content' }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#0071CE" />
                  <path
                    d="M11.9897 5.79471C13.3511 5.79471 14.4442 6.1678 15.2689 6.91399C16.1068 7.64708 16.5257 8.66162 16.5257 9.95762C16.5257 11.2405 16.1133 12.2223 15.2886 12.9031C14.4638 13.5707 13.3708 13.9045 12.0093 13.9045L11.9308 15.4165H9.51548L9.4173 12.0783H10.3009C11.4398 12.0783 12.3169 11.9278 12.9322 11.6267C13.5475 11.3256 13.8551 10.7758 13.8551 9.97726C13.8551 9.40126 13.6849 8.94308 13.3446 8.60271C13.0173 8.26235 12.5657 8.09217 11.9897 8.09217C11.3875 8.09217 10.9162 8.2558 10.5758 8.58308C10.2486 8.91035 10.0849 9.36199 10.0849 9.93799H7.49294C7.47985 9.13944 7.65003 8.42599 8.00348 7.79762C8.35694 7.16926 8.87403 6.67835 9.55476 6.32489C10.2486 5.97144 11.0602 5.79471 11.9897 5.79471ZM10.7133 20.1096C10.2158 20.1096 9.80348 19.9591 9.47621 19.658C9.16203 19.3438 9.00494 18.9576 9.00494 18.4994C9.00494 18.0413 9.16203 17.6616 9.47621 17.3605C9.80348 17.0463 10.2158 16.8893 10.7133 16.8893C11.1977 16.8893 11.5969 17.0463 11.9111 17.3605C12.2253 17.6616 12.3824 18.0413 12.3824 18.4994C12.3824 18.9576 12.2253 19.3438 11.9111 19.658C11.5969 19.9591 11.1977 20.1096 10.7133 20.1096Z"
                    fill="white"
                  />
                </svg>

                <span className="mt-1 ml-1">Investment Portfolio</span>
              </div>

              <div className="mt-10">
                <DonutChart
                  percentages={percentages}
                  colorsArray={donutChartcolors}
                  labels={labels}
                />
              </div>

              <div>
                {labels?.length > 0 &&
                  labels.map(label => {
                    return (
                      <div
                        className="risk-profile-container w-70 d-flex"
                        key={label}
                      >
                        <div>
                          <LittleCicle
                            backgroundColor={donutChartcolors[label]}
                          />
                        </div>
                        <div className="ml-2">
                          <strong>{label}</strong>: {messages[label]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div> */}
          {/* </div> */}

          {false && (
            <div className="card internal-container card-gutter-b mt-5">
              <div className="mt-10">
                <h3 className="text-center" style={{ color: '#0071CE' }}>
                  Portfolio Projection
                </h3>
                <AreaChart />
              </div>
            </div>
          )}
          <div className="text-center my-10">
            <ConfirmButton
              buttonText="Invest Now"
              handleOnClick={() => setOpenPaymentMethodModal(true)}
            />
          </div>

          <TextModal
            open={open}
            setOpen={setOpen}
            text={<RiskProfileInformation />}
            title="Learn more about each portfolio."
          />

          <ReusableModal
            styles={{ width: '60%', mobileWidth: '100%' }}
            open={openPaymentMethodModal}
            setOpen={() => {
              setOpenPaymentMethodModal(false);
              setAlert(alertInitialState);
            }}
            text={
              <PaymentMethodModalContent
                pocketDetails={pocketDetails}
                setActivePaymentMethod={setActivePaymentMethod}
                activePaymentMethod={activePaymentMethod}
                handlePaymentFromModal={createTheGoal}
                closeModal={() => {
                  setAlert(alertInitialState);
                  setActivePaymentMethod(null);
                  setOpenPaymentMethodModal(false);
                }}
                loading={buttonLoading}
                btnText={'Next'}
                displayErrorMessage={<Alert alert={alert} />}
                insufficientFunds={
                  pocketDetails &&
                  Number(pocketDetails?.balance) <
                    convertAmountToNumber(
                      convertAmountToNumber(
                        reduxGoalInvestmentDtls?.initialAmount,
                      ),
                    )
                }
                modalTitle={'Goal'}
                goalDetails={{
                  ...reduxGoalInvestmentDtls,
                  amount: reduxGoalInvestmentDtls?.initialAmount,
                  id: reduxGoalInvestmentDtls?.staticGoalId,
                }}
              />
            }
          />

          <CommonSuccessModal
            open={openCongratulationsModal}
            setOpen={() => setOpenCongratulationsModal(false)}
            content={
              <div className="modal-summary-body">
                <span>You have successfully deposited</span>{' '}
                <span className="font-weight-bolder">
                  {' '}
                  {reduxGoalInvestmentDtls?.currency}{' '}
                  {reduxGoalInvestmentDtls?.initialAmount}{' '}
                </span>
                <span>into your investment</span>
              </div>
            }
            moreClasses={'bg-white'}
            handleButtonClick={() => {
              dispatch(goalReducerActions.ResetGoalInvestmentDetails());
              dispatch(pocketActions.updatePocket(null));
              history.push(`/pocket/${pocketDetails?.id}`);
            }}
          />
        </AnimatedPageContainer>
      )}
    </>
  );
};

export default GoalSummary;
