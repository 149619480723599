import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { getUserProfile } from '../../modules/Profile/_redux/profileCrud';
import { actions as investorProfileActions } from '../../modules/Profile/_redux/profileRedux';
import { isEqual } from 'lodash';

const useUserProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const [error, setError] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const updateInvestorProfile = useCallback(async () => {
    setError(null);

    try {
      const { data: { data: userProfile } = {} } = await getUserProfile();

      const fetchedProfile = {
        ...userProfile,
        loading: profile?.loading,
      };

      if (!isEqual(profile, fetchedProfile)) {
        dispatch(
          investorProfileActions.profileAction({
            user: { ...userProfile?.user },
            investor: { ...userProfile?.investor },
            loading: false,
          }),
        );
      }
    } catch (error) {
      setError({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });

      dispatch(investorProfileActions.profileAction({ loading: false }));
    }
  }, [dispatch]);

  return { updateInvestorProfile, error };
};

export default useUserProfile;
