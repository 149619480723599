import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getPayingNowAmount } from '../../helpers/getPayingNowAmount';
import InvestmentPaymentDetailsPage from './components/InvestmentPaymentDetailsPage';
import convertAmountToNumber from '../../helpers/convertAmountToNumber';
import { fetchPromotionDetails } from './_redux/investmentAction';
import { goalTopUp, investmentInGoal } from '../Goal/_redux/goalActions';
import { getCardPaymentModal } from '../../helpers/cardPaymentModal';
import ApplyVoucher from './components/ApplyVoucher';
import PayWithBankTransfer from './components/PayWithBankTransfer';
import PayWithMpesa from './components/PayWithMpesa';
import { InvestmentAction } from '../../helpers/constants';
import BackArrow from '../Common/BackArrow';
import './components/PaymentDetails/index.scss';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';

const handleGoalInvestment = async (id, textReference, channel) => {
  const response = await investmentInGoal(parseInt(id), textReference, channel);
  return response;
};

const handleGoalTopUp = async (id, textReference, channel) => {
  const response = await goalTopUp(parseInt(id), textReference, channel);
  return response;
};

const { GOAL_INVESTMENT, GOAL_TOPUP } = InvestmentAction;
const actions = [
  { name: GOAL_INVESTMENT, invest: handleGoalInvestment },
  { name: GOAL_TOPUP, invest: handleGoalTopUp },
];

const GoalInvestmentPayment = () => {
  const { id: oneGoalId } = useParams();
  let history = useHistory();

  const [goalData, setGoalData] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [payWithMpesa, showPayWithMpesa] = useState(false);
  const [payWithBankTransfer, showPayWithBankTransfer] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [showPaymentComponent, setShowPaymentComponent] = useState(true);

  const setPayWithBankTransfer = bool => {
    setShowPaymentComponent(!bool);
    showPayWithBankTransfer(bool);
  };

  const setPayWithMpesa = bool => {
    setShowPaymentComponent(!bool);
    showPayWithMpesa(bool);
  };

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('goalDiscountInfo'),
    );
    setDiscount({
      amount: localStorageData?.discountAmount,
      currency: localStorageData?.currency,
    });

    if (reduxGoalInvestmentDtls?.amount && localStorageData?.discountAmount) {
      setTotalAmount(
        getPayingNowAmount(
          convertAmountToNumber(reduxGoalInvestmentDtls?.amount),
          localStorageData?.discountAmount,
        ),
      );
    }

    if (reduxGoalInvestmentDtls?.goalId) {
      setGoalData(reduxGoalInvestmentDtls);
    }
  }, [reduxGoalInvestmentDtls]);

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(8, 'Promo code must be 8 character long')
      .max(8, 'Promo code must be 8 character long'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const initialValues = {
    promoCode: goalData?.promoCode,
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromoCodeSchema,
  });

  const applyPromoCode = async () => {
    setAlertOn(false);
    if (!goalData.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Promo code can not be empty',
        alertMessageType: 'error',
      });
      return;
    }

    if (formik.errors.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: formik.errors.promoCode,
        alertMessageType: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      localStorage.setItem('promoCode', goalData.promoCode);
      const response = await fetchPromotionDetails(
        goalData.promoCode,
        goalData.currency,
      );
      const {
        data: { data: { amount: discountAmount, currency } = {} } = {},
      } = response;
      setDiscount({ amount: discountAmount, currency });
      const amountTOPay = getPayingNowAmount(goalData?.amount, discountAmount);
      setTotalAmount(amountTOPay);

      localStorage.setItem(
        'goalDiscountInfo',
        JSON.stringify({
          discountAmount,
          currency,
          totalAmount: amountTOPay,
        }),
      );
      setUseVoucher(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const payWithCreditCard = async channel => {
    setLoading(true);
    const { amount, currency } = goalData;
    try {
      const response = await getCardPaymentModal(
        convertAmountToNumber(amount),
        currency,
        goalData?.promoCode
          ? goalData?.promoCode
          : localStorage.getItem('promoCode'),
        channel,
      );

      if (!response.data.data) {
        const { data } = response;
        actions.forEach(async item => {
          if (item.name === investmentAction) {
            const result = await item.invest(
              parseInt(oneGoalId),
              data.tx_ref,
              channel,
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('goalDiscountInfo');
              localStorage.removeItem('promoCode');
              history.push('/new-investment/congratulations');
              return;
            }
            return;
          }
        });
        return;
      }

      const { data: { data: { link } = {} } = {} } = response;
      localStorage.removeItem('goalDiscountInfo');
      localStorage.removeItem('promoCode');
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const renderPaymentOptions = () => {
    if (payWithMpesa) {
      return <PayWithMpesa setPayWithMpesa={setPayWithMpesa} />;
    } else if (payWithBankTransfer) {
      return (
        <PayWithBankTransfer
          totalAmount={totalAmount}
          discount={discount}
          amount={goalData?.amount}
          currency={goalData?.currency}
          profile={goalData?.promoCode}
          setPayWithBankTransfer={setPayWithBankTransfer}
        />
      );
    }
  };

  return (
    <AnimatedPageContainer>
      {showPaymentComponent ? (
        <>
          <BackArrow
            text="Back"
            onClick={() => {
              history.goBack();
            }}
          />
          {useVoucher ? (
            <ApplyVoucher
              alertOn={alertOn}
              alert={alert}
              formik={formik}
              getInputClasses={getInputClasses}
              investmentDetails={goalData}
              handleChange={handleChange}
              setUseVoucher={setUseVoucher}
              applyPromoCode={applyPromoCode}
              loading={loading}
              setAlertOn={setAlertOn}
            />
          ) : (
            <InvestmentPaymentDetailsPage
              loading={loading}
              className="goal-payment-btn"
              currency={goalData?.currency}
              setAlertOn={setAlertOn}
              setAlert={setAlert}
              setUseVoucher={setUseVoucher}
              alert={alert}
              alertOn={alertOn}
              goalInvesting={true}
              goalData={goalData}
              discount={discount}
              totalAmount={totalAmount}
              setPayWithMpesa={setPayWithMpesa}
              setPayWithBankTransfer={setPayWithBankTransfer}
              payWithCreditCard={payWithCreditCard}
              activePaymentMethod={activePaymentMethod}
              setActivePaymentMethod={setActivePaymentMethod}
            />
          )}
        </>
      ) : (
        <>{renderPaymentOptions()}</>
      )}
    </AnimatedPageContainer>
  );
};

export default GoalInvestmentPayment;
