import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { injectIntl } from 'react-intl';
import PasswordChecklist from 'react-password-checklist';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import getCountries from '../../../helpers/countries';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { marketingSiteLinks } from '../../../utils/marketingSiteLinks';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import CheckBox from '../../Common/CheckBox';
import CustomInput from '../../Common/CustomInput';
import Dropdown from '../../Common/Dropdown';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { PasswordInput } from '../../Common/PasswordInput';
import { PhoneNumberInput } from '../../Common/PhoneNumberInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';
import { signupInitialValues } from '../InitialValues';
import { signupSchema, signupSchemaForGoogleSignup } from '../Schemas';
import { register, signupWithGoogle } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import '../styles/registration.scss';
import { platformType } from '../../../helpers/constants';

const Registration = props => {
  const { intl, userDetails, originPartnerId, campaignTrackingData } = props;

  const phoneNumber = userDetails?.phoneNumber;

  const [loading, setLoading] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [allCountries, setAllCountries] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [
    phoneNumberWithoutCountryCode,
    setPhoneNumberWithoutCountryCode,
  ] = useState('');
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);

  const profile = useSelector(state => state.profile);

  const userHasSignedInWithGoogle = profile?.user?.hasSignedInWithGoogle;

  const dispatch = useDispatch();

  const [residenceCountry, setResidenceCountry] = useState({
    countryFlag: null,
    currency: null,
    countryCode: null,
  });

  useEffect(() => {
    const countries = getCountries();
    setAllCountries(countries);
    if (phoneNumber) {
      getDefaultPhoneNumber(countries);
    }
  }, []);

  // separate the country code from the phone
  // search for that country
  // if no country is found with the country code ignore the phone number...
  const getDefaultPhoneNumber = countries => {
    const pNumberWithoutSpecialChars = phoneNumber.replace(/[^0-9]/g, '');
    const countryCode = pNumberWithoutSpecialChars.substring(0, 3);
    const phoneNumberWithoutCountryCode = pNumberWithoutSpecialChars.substring(
      3,
    );
    const countryRegistered = countries.find(
      country => country?.countryCallingCode === countryCode,
    );

    if (countryRegistered) {
      setSelectedCountry(countryRegistered);
      setPhoneNumberWithoutCountryCode(phoneNumberWithoutCountryCode);
    }
  };

  let history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  if (phoneNumberWithoutCountryCode) {
    userDetails.phoneNumber = phoneNumberWithoutCountryCode;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: signupInitialValues(
      userDetails?.phoneNumber ? { ...userDetails } : { ...profile.user },
    ),
    validationSchema: userHasSignedInWithGoogle
      ? signupSchemaForGoogleSignup(intl)
      : signupSchema(intl),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        enableLoading();
        const wizardData = JSON?.parse(localStorage?.getItem('wizard'));

        const fullPhoneNumber = getFullPhoneNumber(
          selectedCountry?.countryCallingCode,
          values.phoneNumber,
        );

        const isValid = validatePhoneNumber(
          fullPhoneNumber,
          values.phoneNumber,
        );
        setValidPhoneNumber(isValid);

        let payload = {
          email: values.email.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: fullPhoneNumber,
          country: residenceCountry?.countryCode,
          registrationPlatform: platformType.WEB_APP,
        };

        if (!userHasSignedInWithGoogle) {
          payload.password = values.password;
        }

        if (originPartnerId) {
          payload.originPartnerId = originPartnerId;
        }

        if (wizardData) {
          payload = { ...payload, ...wizardData };
        }

        if (values !== '' && isValid) {
          const response = userHasSignedInWithGoogle
            ? await signupWithGoogle(payload)
            : await register(payload, campaignTrackingData);

          // google tag manager integration
          TagManager.dataLayer({
            dataLayer: {
              event: 'userFormSubmit',
              user: {
                name: `${payload.firstName} ${payload.lastName}`,
                email: payload.email,
                phoneNumber: payload.phoneNumber,
              },
            },
          });

          disableLoading();
          setSubmitting(false);

          // :TODO: SAVE THIS IN GLOBAL STATE
          localStorage.setItem('userData', JSON.stringify(response?.data));
          const investorInformation = response?.data?.data?.investor;
          delete response?.data?.data?.investor;

          const userData = userHasSignedInWithGoogle
            ? {
                ...profile?.user,
                ...response?.data?.user,
              }
            : {
                ...profile?.user,
                ...response?.data?.data,
              };

          dispatch(
            investorProfileActions.profileAction({
              ...profile,
              user: userData,
              investor: { ...profile?.investor, ...investorInformation },
              accessToken: response?.data?.accessToken,
            }),
          );

          localStorage.removeItem('idToken');
          localStorage.removeItem('wizard');

          disableLoading();
          setSubmitting(false);
          history.push('/auth/selectAccountType');
        } else {
          disableLoading();
          setStatus('Use a valid phone number');
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error?.response?.data?.message);
        disableLoading();
      }
    },
  });

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
  };

  const handleSelectResidenceCountry = country => {
    setResidenceCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    setSelectedCountry(country);
    setOpenCountryDropdown(false);
  };

  const { password } = formik.values;

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      {!userHasSignedInWithGoogle ? (
        <>
          <ExternalPageTitle
            title="Create account"
            subTitle="Enter your details to create an account"
          />
        </>
      ) : null}

      <div className="text-14 text-blue font-weight-500">
        Please, make sure that the full name exactly matches the legal name on
        your national ID document.
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && registerSuccess === true ? (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        {formik.status && !registerSuccess ? (
          <div className="my-5">
            <div className="text-left font-weight-bold text-red-500">
              {formik.status}
            </div>
          </div>
        ) : null}
        {/* end: Alert */}

        {/* begin: First name */}
        <CustomInput
          type="text"
          placeholder="First name"
          name="firstName"
          formik={formik}
          inputWidth={'default'}
        />
        {/* end: First name */}

        {/* begin: Lastname */}
        <CustomInput
          type="text"
          placeholder="Last name"
          name="lastName"
          formik={formik}
          inputWidth={'default'}
        />
        {/* end: Last name */}

        {/* begin: Email */}
        <CustomInput
          type="email"
          placeholder="Email"
          name="email"
          formik={formik}
          inputWidth={'default'}
          disabled={userHasSignedInWithGoogle}
        />
        {/* end: Email */}

        <div className="mx-auto input-spacing">
          <Dropdown
            handleOpenSelector={handleCountryDropdown}
            dropdownPlaceholder={'Select country of residence'}
            selectedOption={residenceCountry}
            classes={'mt-7 pr-5 pl-2'}
            name={'residenceCountry'}
          />
        </div>

        {/* begin: Phone number */}
        {residenceCountry?.countryCode ? (
          <PhoneNumberInput
            setOpen={setOpen}
            selectedCountry={selectedCountry}
            formik={formik}
            validPhoneNumber={validPhoneNumber}
          />
        ) : null}
        {/* end: Phone number */}

        {!userHasSignedInWithGoogle ? (
          <>
            {/* begin: Password */}
            <PasswordInput
              viewPassword={viewPassword}
              formik={formik}
              setViewPassword={setViewPassword}
              name={'password'}
              placeholder="Password"
            >
              <div className="mt-5">
                {formik.touched.password && formik.errors.password && (
                  <PasswordChecklist
                    rules={[
                      'minLength',
                      'maxLength',
                      'specialChar',
                      'number',
                      'capital',
                      'lowercase',
                    ]}
                    messages={{
                      minLength: 'At least 8 characters.',
                      maxLength: 'At most 40 characters.',
                      lowercase: 'At least one lower case letter.',
                      capital: 'At least one upper case letter.',
                      number: 'At least one number.',
                      specialChar: 'At least one special character.',
                    }}
                    minLength={8}
                    maxLength={40}
                    value={password}
                    validTextColor="green"
                    invalidTextColor="#F64E60"
                    hideIcon
                  />
                )}
              </div>
            </PasswordInput>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <PasswordInput
              viewPassword={viewConfirmPassword}
              formik={formik}
              setViewPassword={setViewConfirmPassword}
              name="changePassword"
              placeholder="Confirm Password"
              passwordContainerSpacing={'mt-9 mb-7'}
            />
            {/* end: Confirm Password */}
          </>
        ) : null}

        {/* begin: Terms and Conditions */}
        <CheckBox
          name="acceptTerms"
          formik={formik}
          customLabel={
            <div className="text-11">
              <span className="text-dark-gray-450">
                I have read and agree with
              </span>{' '}
              <a
                href={marketingSiteLinks.termsAndConditions}
                className="text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              <span className="text-dark-gray-450">&</span>{' '}
              <a
                className="text-blue"
                href={marketingSiteLinks.privacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
          }
        />

        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
          </div>
        ) : null}
        {/* end: Terms and Conditions */}

        <ButtonsContainer justifyContent="justify-content-between">
          {' '}
          <div className="auth-buttons-size">
            <Link to="/auth/login" className="w-100">
              <CancelButton buttonText="Cancel" buttonWidth={'w-100'} />
            </Link>
          </div>
          <ConfirmButton
            buttonType={'submit'}
            buttonText="Sign up"
            loading={loading}
            disable={formik.isSubmitting || !formik.values.acceptTerms}
            buttonWidth={'auth-buttons-size'}
            changeText={false}
          />
        </ButtonsContainer>

        <DoYouHaveAccount
          question="Don't have an account?"
          action={'Sign in'}
          link={'/auth/login'}
          margin="mt-4"
        />
      </form>

      <SelectOptionsModal
        open={open}
        handleClose={() => setOpen(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={allCountries}
        handleSelect={handleSelectResidenceCountry}
        placeholder={'Select Country'}
        selectedCountry={residenceCountry}
      />
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(Registration));
