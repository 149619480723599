import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { countries } from 'countries-list';
import _ from 'lodash';
import moment from 'moment/moment';

import {
  EmploymentStatus,
  SourceOfFunds,
  SourceOfFundsObject,
  allowWhiteSpace,
  allowWhiteSpaceError,
  genderOptions,
  kycStatuses,
} from '../../../helpers/constants';
import Alert from '../../Common/Alert';
import getCountries from '../../../helpers/countries';
import {
  days,
  getDaysInMonth,
  months,
  getYears as years,
} from '../../../helpers/investmentHelpers';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import SelectPhoneNumber from '../../Common/SelectPhoneNumber';
import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import * as profile from '../../Profile/_redux/profileRedux';

import { Country } from 'country-state-city';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { getCountryCode } from '../../../helpers/getCountryCode';
import { getGender } from '../../../helpers/getGender';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import Back from '../../Common/BackArrow';
import Dropdown from '../../Common/Dropdown';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import OptionsModal from '../../Common/OptionsModal';
import ReusableDropdown from '../../Common/ReusableDropdown';
import CustomInput from '../../Common/CustomInput';
import { DateValidation } from '../../Individual/components/DateValidation';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import * as kyc from '../../KYC/_redux/kycRedux';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { extractDateParts } from '../../../helpers/Date/extractDateParts';

const PersonalDetails = props => {
  const history = useHistory();
  const { hideButtons, disableInput, handleStepChange, id: stepperId } = props;

  const { investor, user } = useSelector(state => state.profile);

  const existingAccumulatedChanges = useSelector(state => state.kyc) || {};

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validNextOfKinPhoneNumber, setValidNextOfKinPhoneNumber] = useState(
    true,
  );
  const [allCountries, setAllCountries] = useState(getCountries());
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedDays, setUpdatedDays] = useState(days);
  const [selectedYear, setSelectedYear] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [errorExist, setErrorExist] = useState();
  const [selectedOption, setSelectedOption] = useState({
    gender: existingAccumulatedChanges?.gender
      ? getGender(existingAccumulatedChanges?.gender)
      : getGender(investor?.gender),
    employmentStatus: {
      label:
        existingAccumulatedChanges?.employmentStatus ||
        investor?.employmentStatus ||
        '',
      value:
        existingAccumulatedChanges?.employmentStatus ||
        investor?.employmentStatus ||
        '',
    },

    sourceOfFunds: {
      value:
        existingAccumulatedChanges?.sourceOfFunds ||
        investor?.sourceOfFunds ||
        '',
      label: existingAccumulatedChanges?.sourceOfFunds
        ? SourceOfFundsObject[existingAccumulatedChanges?.sourceOfFunds]
        : investor?.sourceOfFunds
        ? SourceOfFundsObject[investor?.sourceOfFunds]
        : '',
    },
  });
  const [openCountryCallingCode, setOpenCountryCallingCode] = useState(false);
  const [
    openEmploymentStatusDropdown,
    setOpenEmploymentStatusDropdown,
  ] = useState(false);
  const [openSourceOfFund, setOpenSourceOfFund] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();

  const [openCountryDetails, setOpenCountryDetails] = useState(false);
  const [countryDetails, setCountryDetails] = useState(
    existingAccumulatedChanges?.country
      ? {
          countryFlag: `flag-icon-${existingAccumulatedChanges?.country?.toLowerCase()}`,
          currency: Country.getCountryByCode(
            existingAccumulatedChanges?.country,
          )?.name,
          countryCode: existingAccumulatedChanges?.country,
        }
      : {
          countryFlag: `flag-icon-${investor?.country?.toLowerCase()}`,
          currency: Country.getCountryByCode(investor?.country)?.name,
          countryCode: investor?.country,
        },
  );

  const [
    selectedNextOfKinCountryCallingCode,
    setSelectedNextOfKinCountryCallingCode,
  ] = useState(
    existingAccumulatedChanges?.nextOfKinContact
      ? {
          countryCallingCode: parsePhoneNumber(
            existingAccumulatedChanges?.nextOfKinContact,
          ).countryCallingCode,
          name:
            countries[
              parsePhoneNumber(existingAccumulatedChanges?.nextOfKinContact)
                .country
            ]?.name,
          flag: `flag-icon-${parsePhoneNumber(
            existingAccumulatedChanges?.nextOfKinContact,
          ).country.toLowerCase()}`,
        }
      : investor?.nextOfKinContact
      ? {
          countryCallingCode: parsePhoneNumber(investor?.nextOfKinContact)
            .countryCallingCode,
          name:
            countries[parsePhoneNumber(investor?.nextOfKinContact).country]
              ?.name,
          flag: `flag-icon-${parsePhoneNumber(
            investor?.nextOfKinContact,
          ).country.toLowerCase()}`,
        }
      : null,
  );

  const [
    openNextOfKinCountryCallingCode,
    setOpenNextOfKinCountryCallingCode,
  ] = useState(false);

  const dispatch = useDispatch();

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleEmploymentStatusDropdown = () => {
    setOpenEmploymentStatusDropdown(!openEmploymentStatusDropdown);
  };

  const handleSourceOfFundsDropdown = () => {
    setOpenSourceOfFund(!openSourceOfFund);
  };

  const closeEmploymentStatusDropdown = () => {
    setOpenEmploymentStatusDropdown(false);
  };

  const closeSourceOfFundDropdown = () => {
    setOpenSourceOfFund(false);
  };

  const updateCountryDetails = async () => {
    const countryCodeOrName = existingAccumulatedChanges?.country
      ? existingAccumulatedChanges?.country?.toLowerCase()
      : investor?.country?.toLowerCase();

    const { countryCode } = await getCountryCode(countryCodeOrName);

    setCountryDetails({
      countryFlag: `flag-icon-${countryCode?.toLowerCase()}`,
      currency: Country.getCountryByCode(countryCode)?.name,
      countryCode: countryCode,
    });
  };

  useEffect(() => {
    updateCountryDetails();
    handleStepChange({ next: stepperId });
    updateCountryDetails();

    setSelectedCountry(
      existingAccumulatedChanges?.phoneNumber
        ? {
            countryCallingCode: parsePhoneNumber(
              existingAccumulatedChanges.phoneNumber,
            ).countryCallingCode,
            name:
              countries[
                parsePhoneNumber(existingAccumulatedChanges.phoneNumber).country
              ]?.name,
            flag: `flag-icon-${parsePhoneNumber(
              existingAccumulatedChanges.phoneNumber,
            ).country.toLowerCase()}`,
          }
        : investor?.phoneNumber
        ? {
            countryCallingCode: parsePhoneNumber(investor.phoneNumber)
              .countryCallingCode,
            name:
              countries[parsePhoneNumber(investor.phoneNumber).country]?.name,
            flag: `flag-icon-${parsePhoneNumber(
              investor.phoneNumber,
            ).country.toLowerCase()}`,
          }
        : null,
    );
  }, []);

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
    setOpenCountryCallingCode(false);
  };

  const selectNextOfKinCountryCallingCode = country => {
    setSelectedNextOfKinCountryCallingCode(country);
    setOpenNextOfKinCountryCallingCode(false);
  };

  const changeCountryDetails = country => {
    setCountryDetails({
      ...country,
      countryFlag: country.flag,
      currency: country?.name,
    });
    setOpen(false);
    setOpenCountryDetails(false);
  };

  const alertRef = useRef(null);

  const dateOfBirth = extractDateParts(
    existingAccumulatedChanges?.dateOfBirth
      ? existingAccumulatedChanges?.dateOfBirth
      : investor?.dateOfBirth,
  );

  const initialValues = {
    firstName: user?.firstName || '',

    middleName: user?.middleName || '',

    lastName: user?.lastName || '',

    phoneNumber: investor?.phoneNumber
      ? parsePhoneNumber(investor?.phoneNumber).nationalNumber
      : '',

    email: user?.email || '',

    dateOfBirth: investor?.dateOfBirth
      ? moment(investor?.dateOfBirth).format('YYYY-MM-DD')
      : '',

    year: dateOfBirth?.year,

    month: dateOfBirth?.month,

    day: dateOfBirth?.day,

    country: existingAccumulatedChanges?.country || investor?.country || '',

    residentialAddress:
      existingAccumulatedChanges?.residentialAddress ||
      investor?.residentialAddress ||
      '',

    postalCode:
      existingAccumulatedChanges?.postalCode || investor?.postalCode || '',

    city: existingAccumulatedChanges?.city || investor?.city || '',

    sourceOfFunds:
      existingAccumulatedChanges?.sourceOfFunds ||
      investor?.sourceOfFunds ||
      '',

    nextOfKinNames:
      existingAccumulatedChanges?.nextOfKinNames ||
      investor?.nextOfKinNames ||
      '',

    nextOfKinContact: existingAccumulatedChanges?.nextOfKinContact
      ? parsePhoneNumber(existingAccumulatedChanges?.nextOfKinContact)
          .nationalNumber
      : investor?.nextOfKinContact
      ? parsePhoneNumber(investor?.nextOfKinContact).nationalNumber
      : '',

    employmentStatus:
      existingAccumulatedChanges?.employmentStatus ||
      investor?.employmentStatus ||
      '',

    nextOfKinEmail:
      existingAccumulatedChanges?.nextOfKinEmail ||
      investor?.nextOfKinEmail ||
      '',
  };

  const DetailsSchemaStageOne = Yup.object().shape({
    firstName: Yup.string()
      .min(2)
      .required('First Name is Required')
      .matches(allowWhiteSpace, allowWhiteSpaceError),

    middleName: Yup.string().matches(allowWhiteSpace, allowWhiteSpaceError),

    email: Yup.string(),

    lastName: Yup.string().matches(allowWhiteSpace, allowWhiteSpaceError),

    day: Yup.string()
      .min(1, 'Required field')
      .required('Day is Required'),

    month: Yup.string()
      .min(1, 'Required field')
      .required('Month is Required'),

    year: Yup.string()
      .min(1, 'Required field')
      .required('Year is Required'),

    city: Yup.string()
      .min(1, 'Required Field')
      .required('City is Required'),

    nextOfKinNames: Yup.string()
      .min(1, 'Required Field')
      .required('Next of Kin Full Name is Required'),

    nextOfKinEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters'),
  });

  const DetailsSchemaStageTwo = Yup.object().shape({
    firstName: Yup.string()
      .min(2)
      .required('First Name is Required')
      .matches(allowWhiteSpace, allowWhiteSpaceError),

    middleName: Yup.string().matches(allowWhiteSpace, allowWhiteSpaceError),

    email: Yup.string(),

    lastName: Yup.string().matches(allowWhiteSpace, allowWhiteSpaceError),

    day: Yup.string()
      .min(1, 'Required field')
      .required('Day is Required'),

    month: Yup.string()
      .min(1, 'Required field')
      .required('Month is Required'),

    year: Yup.string()
      .min(1, 'Required field')
      .required('Year is Required'),

    city: Yup.string()
      .min(1, 'Required Field')
      .required('City is Required'),

    nextOfKinNames: Yup.string()
      .min(1, 'Required Field')
      .required('Next of Kin is Required'),

    nextOfKinEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters'),

    residentialAddress: Yup.string()
      .trim()
      .required('Residential Address is Required'),

    postalCode: Yup.string()
      .trim()
      .required('Postal Code is Required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      investor?.kycLevel?.level === 1
        ? DetailsSchemaStageOne
        : DetailsSchemaStageTwo,
    onSubmit: async values => {
      const {
        nextOfKinContact,
        phoneNumber,
        firstName,
        middleName,
        lastName,
        year,
        month,
        day,
        residentialAddress,
        postalCode,
        city,
        nextOfKinNames,
        nextOfKinEmail,
      } = values;

      const investorFullPhoneNumber = getFullPhoneNumber(
        selectedCountry?.countryCallingCode,
        phoneNumber,
      );

      const isInvestorPhoneNumberValid = validatePhoneNumber(
        investorFullPhoneNumber,
        phoneNumber,
      );

      const fullNextOfKinPhoneNumber = getFullPhoneNumber(
        selectedNextOfKinCountryCallingCode?.countryCallingCode,
        nextOfKinContact,
      );

      const isNextOfKinPhoneNumberValid = validatePhoneNumber(
        fullNextOfKinPhoneNumber,
        nextOfKinContact,
      );

      setValidPhoneNumber(isInvestorPhoneNumberValid);
      setValidNextOfKinPhoneNumber(isNextOfKinPhoneNumberValid);

      if (!isInvestorPhoneNumberValid || !isNextOfKinPhoneNumberValid) return;

      const fields = {
        country: selectedCountry.countryCallingCode,
        gender: selectedOption.gender.value,
        employmentStatus: selectedOption.employmentStatus.value,
        sourceOfFunds: selectedOption.sourceOfFunds,
      };

      const noErrors = checkForErrors(fields, ['nextOfKinEmail']);
      if (!noErrors) {
        setErrorExist(true);
        return;
      }

      setLoading(true);
      if (values !== '' && isInvestorPhoneNumberValid) {
        const currentUserInfo = {
          firstName: user?.firstName,
          middleName: user?.middleName || '',
          lastName: user?.lastName,
          phoneNumber: investor?.phoneNumber,
          gender: investor?.gender,
          dateOfBirth:
            investor?.dateOfBirth &&
            moment(investor?.dateOfBirth).format('YYYY-MM-DD'),
          country: investor?.country,
          residentialAddress: investor?.residentialAddress,
          postalCode: investor?.postalCode,
          city: investor?.city,
          sourceOfFunds: investor?.sourceOfFunds,
          nextOfKinNames: investor?.nextOfKinNames,
          nextOfKinEmail: investor?.nextOfKinEmail,
          nextOfKinContact: investor?.nextOfKinContact,
          employmentStatus: investor?.employmentStatus,
        };

        const payload = {
          firstName,
          middleName,
          lastName,
          gender: selectedOption.gender.value,
          phoneNumber: `+${selectedCountry?.countryCallingCode}${phoneNumber}`,
          dateOfBirth: moment(`${year}-${month}-${day}`).format('YYYY-MM-DD'),

          country: countryDetails?.countryCode,
          residentialAddress,
          postalCode,
          city,
          sourceOfFunds: selectedOption?.sourceOfFunds.value,
          nextOfKinNames,
          nextOfKinEmail,
          nextOfKinContact: `+${selectedNextOfKinCountryCallingCode?.countryCallingCode}${nextOfKinContact}`,
          employmentStatus: selectedOption?.employmentStatus.value,
        };

        if (_.isEqual(currentUserInfo, payload)) {
          dispatch(kycActions?.updateKycInfo(payload));
          history.push('/manage-account/investor-information/documentation');
        } else {
          if (investor?.kycStatus === kycStatuses.SUBMITTED) {
            setLoading(false);
            setAlertOn(true);
            setAlert({
              alertMessage:
                'Investor information change is not allowed as your information verification is in progress.',
              alertMessageType: 'error',
            });
            return;
          }

          dispatch(kycActions?.updateKycInfo(payload));
          history.push('/manage-account/investor-information/documentation');
        }
      } else {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: 'Use a valid phone number',
          alertMessageType: 'alert-light-danger',
        });
      }
    },
  });

  const handleSelectOption = (item, name) => {
    setSelectedOption({ ...selectedOption, [name]: item });
    closeDropdown();
    closeEmploymentStatusDropdown();
    closeSourceOfFundDropdown();
  };

  return (
    <>
      {hideButtons ? (
        <Back text={'Back'} onClick={() => history.goBack()} />
      ) : null}
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          {hideButtons ? null : (
            <div className="px-0 px-md-20 px-lg-20">
              <p className="px-0 font-weight-bold text-center px-md-20 px-lg-20">
                Personal Details
              </p>
            </div>
          )}

          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title="First Name"
              name="firstName"
              formik={formik}
              getInputClasses={getInputClasses}
              disabled={true}
            />

            <CustomInput
              type="text"
              title="Middle Name"
              name="middleName"
              formik={formik}
              getInputClasses={getInputClasses}
            />
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title="Last Name"
              name="lastName"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
              disabled={true}
            />
            {/* End names */}

            {/* start Email */}
            <CustomInput
              type="email"
              title="Email Address"
              name="email"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
              disabled={true}
            />
            {/* end Email */}
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <DropdownContainer>
              <DropdownTitle title={'Gender'} />

              <ReusableDropdown
                handleOpenSelector={handleDropdown}
                dropdownPlaceholder={'Gender'}
                selectedOption={selectedOption?.gender?.label}
                classes={'pr-2'}
                name={'gender'}
              />

              <FieldErrorMessage
                errorExist={errorExist}
                errorMessage={'Gender is Required'}
                fieldValue={selectedOption.gender.value}
              />
            </DropdownContainer>
            <DropdownContainer>
              <DropdownTitle title={'Phone Number'} />

              <div className="d-flex w-100">
                <div className="width-40">
                  <SelectPhoneNumber
                    setOpen={setOpenCountryCallingCode}
                    selectedCountry={selectedCountry}
                  />
                </div>
                <div className="ml-2 width-60">
                  <input
                    name="phoneNumber"
                    className={`w-100 border-gray-450 height-45 px-4 border-radius-6 font-weight-500 text-15 text-dark-600`}
                    type="tel"
                    {...formik.getFieldProps('phoneNumber')}
                    placeholder="Phone Number"
                    autoComplete="off"
                  />

                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <ValidationErrors
                      errorMessage={formik.errors.phoneNumber}
                    />
                  ) : null}
                  {!validPhoneNumber ? (
                    <ValidationErrors
                      errorMessage={'Enter a valid phone number e.g. 7********'}
                    />
                  ) : null}
                </div>
              </div>
            </DropdownContainer>{' '}
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <DropdownContainer>
              <DropdownTitle title={'Date of Birth'} />
              <div className="dateOfBirthInput">
                <div className="d-flex extracted-year">
                  <select
                    name="year"
                    className={`single-select input-padding font-weight-500 text-15 text-dark-600 ${getInputClasses(
                      'year',
                    )}`}
                    {...formik.getFieldProps('year')}
                    onChange={event => {
                      formik.setFieldValue('year', event.target.value);
                      setSelectedYear(event.target.value); // Set the selected year here
                    }}
                  >
                    <option value="" disabled hidden>
                      Year
                    </option>
                    {years().map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex extracted-month">
                  <select
                    name="month"
                    className={`single-select input-padding font-weight-500 text-15 text-dark-600`}
                    {...formik.getFieldProps('month')}
                    onChange={event => {
                      formik.setFieldValue('month', event.target.value);
                      const selectedMonth = event.target.value;
                      // Calculate and set the days based on the selected month
                      setUpdatedDays(
                        getDaysInMonth(selectedMonth, selectedYear),
                      );
                    }}
                  >
                    <option value="" disabled hidden>
                      Month
                    </option>
                    {months.map(month => (
                      <option key={month.value} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex extracted-day">
                  <select
                    name="day"
                    className={`single-select input-padding font-weight-500 text-15 text-dark-600`}
                    {...formik.getFieldProps('day')}
                  >
                    <option value="" disabled hidden>
                      Day
                    </option>
                    {updatedDays.map(day => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <DateValidation formik={formik} />
            </DropdownContainer>

            <DropdownContainer>
              <DropdownTitle title={'Country'} />

              <Dropdown
                handleOpenSelector={() =>
                  setOpenCountryDetails(!openCountryDetails)
                }
                dropdownPlaceholder={'Select Country'}
                selectedOption={countryDetails}
                classes={'border-light-blue-400 pr-2'}
                name={'country'}
              />

              <FieldErrorMessage
                errorExist={errorExist}
                errorMessage={'Country is Required'}
                fieldValue={countryDetails?.name}
              />
            </DropdownContainer>
          </div>

          {investor?.kycLevel?.level > 1 ? (
            <>
              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="City"
                  name="city"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                  disabled={disableInput}
                />

                <CustomInput
                  type="text"
                  title="Residential Address"
                  name="residentialAddress"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="Postal Code"
                  name="postalCode"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
                <DropdownContainer>
                  <DropdownTitle title={'Source of Funds'} />

                  <ReusableDropdown
                    handleOpenSelector={handleSourceOfFundsDropdown}
                    dropdownPlaceholder={'Source Of Funds'}
                    selectedOption={selectedOption?.sourceOfFunds?.label}
                    classes={'pr-2'}
                    name={'sourceOfFunds'}
                  />
                </DropdownContainer>{' '}
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Employment Status'} />

                  <ReusableDropdown
                    handleOpenSelector={handleEmploymentStatusDropdown}
                    dropdownPlaceholder={'Employment Status'}
                    selectedOption={selectedOption?.employmentStatus?.label}
                    classes={'pr-2'}
                    name={'employmentStatus'}
                  />
                </DropdownContainer>

                <CustomInput
                  type="text"
                  title="Next of Kin"
                  name="nextOfKinNames"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Next of Kin Contact'} />
                  <div className="d-flex w-100">
                    <div className="width-40">
                      <SelectPhoneNumber
                        setOpen={setOpenNextOfKinCountryCallingCode}
                        selectedCountry={selectedNextOfKinCountryCallingCode}
                      />
                    </div>
                    <div className="ml-2 width-60">
                      <input
                        name="nextOfKinContact"
                        className={`w-100 border-gray-450 height-45 border-radius-8 px-4 font-weight-500 text-13 text-dark-600`}
                        type="tel"
                        {...formik.getFieldProps('nextOfKinContact')}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />

                      {formik.touched.nextOfKinContact &&
                      formik.errors.nextOfKinContact ? (
                        <ValidationErrors
                          errorMessage={formik.errors.nextOfKinContact}
                        />
                      ) : null}
                      {!validNextOfKinPhoneNumber ? (
                        <ValidationErrors
                          errorMessage={
                            'Enter a valid phone number e.g. 7********'
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </DropdownContainer>

                <CustomInput
                  type="email"
                  title="Next of Kin Email"
                  name="nextOfKinEmail"
                  formik={formik}
                  getInputClasses={getInputClasses}
                />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between flex-wrap">
                <CustomInput
                  type="text"
                  title="City"
                  name="city"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                  disabled={disableInput}
                />
                <DropdownContainer>
                  <DropdownTitle title={'Source of Funds'} />

                  <ReusableDropdown
                    handleOpenSelector={handleSourceOfFundsDropdown}
                    dropdownPlaceholder={'Source Of Funds'}
                    selectedOption={selectedOption?.sourceOfFunds?.label}
                    classes={'pr-2'}
                    name={'sourceOfFunds'}
                  />
                </DropdownContainer>{' '}
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Employment Status'} />

                  <ReusableDropdown
                    handleOpenSelector={handleEmploymentStatusDropdown}
                    dropdownPlaceholder={'Employment Status'}
                    selectedOption={selectedOption?.employmentStatus?.label}
                    classes={'pr-2'}
                    name={'employmentStatus'}
                  />
                </DropdownContainer>

                <CustomInput
                  type="text"
                  title="Next of Kin Full name"
                  name="nextOfKinNames"
                  formik={formik}
                  getInputClasses={getInputClasses}
                  required={true}
                />
              </div>

              <div className="d-flex justify-content-between flex-wrap">
                <DropdownContainer>
                  <DropdownTitle title={'Next of Kin Contact'} />
                  <div className="d-flex w-100">
                    <div className="width-40">
                      <SelectPhoneNumber
                        setOpen={setOpenNextOfKinCountryCallingCode}
                        selectedCountry={selectedNextOfKinCountryCallingCode}
                      />
                    </div>
                    <div className="ml-2 width-60">
                      <input
                        name="nextOfKinContact"
                        className={`w-100 border-gray-450 height-45 border-radius-6 px-4 font-weight-500 text-15 text-dark-600`}
                        type="tel"
                        {...formik.getFieldProps('nextOfKinContact')}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />

                      {formik.touched.nextOfKinContact &&
                      formik.errors.nextOfKinContact ? (
                        <ValidationErrors
                          errorMessage={formik.errors.nextOfKinContact}
                        />
                      ) : null}
                      {!validNextOfKinPhoneNumber ? (
                        <ValidationErrors
                          errorMessage={
                            'Enter a valid phone number e.g. 7********'
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </DropdownContainer>

                <CustomInput
                  type="email"
                  title="Next of Kin Email"
                  name="nextOfKinEmail"
                  formik={formik}
                  getInputClasses={getInputClasses}
                />
              </div>
            </>
          )}

          {hideButtons ? (
            <div className="flex-wrap d-flex justify-content-center buttons-gap mt-15 ">
              <CancelButton
                buttonText="Cancel"
                handleOnClick={() =>
                  history.push('/manage-account/information')
                }
              />

              <ConfirmButton
                buttonType={'submit'}
                buttonText={'Next'}
                loading={loading}
              />
            </div>
          ) : null}
          {alertOn && (
            <div className="mt-5">
              <Alert alertRef={alertRef} alert={alert} />
            </div>
          )}
        </KycPageContentWrapper>
      </form>
      <SelectOptionsModal
        open={open}
        handleClose={() => setOpen(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={genderOptions}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.gender}
        placeholder={'Gender'}
        name={'gender'}
      />

      <SelectOptionsModal
        open={openCountryCallingCode}
        handleClose={() => setOpenCountryCallingCode(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />

      <SelectOptionsModal
        open={openNextOfKinCountryCallingCode}
        handleClose={() => setOpenNextOfKinCountryCallingCode(false)}
        allCountries={allCountries}
        handleSelect={selectNextOfKinCountryCallingCode}
        selectedCountry={selectedNextOfKinCountryCallingCode}
      />

      <SelectOptionsModal
        open={openCountryDetails}
        handleClose={() => setOpenCountryDetails(false)}
        allCountries={allCountries}
        handleSelect={changeCountryDetails}
        selectedCountry={countryDetails}
      />

      <OptionsModal
        open={openEmploymentStatusDropdown}
        handleClose={() => closeEmploymentStatusDropdown()}
        items={EmploymentStatus}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.employmentStatus}
        placeholder={'Employment Status'}
        name={'employmentStatus'}
      />

      <OptionsModal
        open={openSourceOfFund}
        handleClose={() => closeSourceOfFundDropdown()}
        items={SourceOfFunds}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption?.sourceOfFunds}
        placeholder={'Source of Funds'}
        name={'sourceOfFunds'}
      />
    </>
  );
};

const mapStateToProps = ({ kyc, profile }) => ({
  kyc,
  profile,
});

export default injectIntl(
  connect(mapStateToProps, { ...kyc.actions, ...profile?.actions })(
    PersonalDetails,
  ),
);
