import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

import { moneyFormat } from '../../../../helpers/moneyFormat';

export const PortfolioValueOrPocket = ({
  icon,
  label,
  amount,
  link,
  classes,
  isPocket,
}) => {
  const history = useHistory();
  const pocketIsActive = amount || amount === 0;
  return (
    <div
      className={`${classes} ${
        pocketIsActive ? 'cursor-pointer' : ''
      } d-flex mt-5 portfolio-value-and-pocket-container`}
      onClick={() => pocketIsActive && history.push(link)}
    >
      <div className="d-flex flex-column">
        <div>
          <img src={icon} alt="Portfolio value" />
        </div>
        <div className={`font-weight-500 text-13 `}>{label}</div>
      </div>

      <motion.div
        className={`${
          isPocket ? 'pocket-balance' : 'portfolio-value-balance'
        } w-100 d-flex justify-content-between align-items-center border-radius-20 border-light-blue-540 px-4`}
        whileHover={pocketIsActive ? { scale: 1.05 } : null}
        transition={pocketIsActive ? { type: 'spring', stiffness: 100 } : null}
      >
        <div className="font-weight-600 text-13">
          {pocketIsActive ? moneyFormat(amount) : 0}
        </div>
        {pocketIsActive ? (
          <img
            src={`/media/svg/icons/Navigation/Arrow-right.svg`}
            alt="right arrow"
          />
        ) : null}
      </motion.div>
    </div>
  );
};
